.owned{
    .col{
        overflow: hidden;

        .rCard{
            a{
                text-decoration: none;
                font-size: medium;
            }
        }
    }
}