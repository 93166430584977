@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import "~bootstrap/scss/mixins";

.mint{
    background-color: $gray-800;
    min-height: 7rem;
    
    .logo{
        background-image: url('./12-lapins.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 7rem;
        background-position: right;

    }

    @include media-breakpoint-up(md) {
        .logo {
            height: 10rem;
            background-position: top right;
        }
    }

    @include media-breakpoint-up(lg) {
        .logo {
            height: 15rem;
        }
    }
    
    .title{
        .info-link {
            font-size:small;
            color:white;
            display: inline-block;
            vertical-align: top;
            margin-left: 4px;
        }
    }

    .prize-link a{
        font-size:small;
        color:white;
    }


    .mintInfo{
        text-align: left;

        p{
            margin-bottom: 0;
        }

        .mintCount{
            font-size: small;
        }

        .refCode{
            font-size:small;
            color: $gray-400;
        }
    }

    .timesView{
        font-size: smaller;
    }

    .winnersView{
        font-size: smaller;

        .mintedlabel{
            text-align: right;
            font-style: italic;
            color: $gray-400;
        }

        .winnerItem{
            
            .certHolder{
                position: relative;

                .bnb-amt{
                    position: relative;
                    top: -2px;
                    left: 2px;
                    width: 55px;
                    display: inline-block;
                }

                .certback{
                    font-size: 1.5rem;
                }

                .owned{
                    position: absolute;
                    font-size: 0.6rem;
                    left: 1.4rem;
                    top: -0.2rem;
                }
    
                .score{
                    position: absolute;
                    top: 0.28rem;
                    left: 0.54rem;
                    font-size: 0.7rem;
                    color: black;
                    font-weight: bold;
                }
    
            }

        }
    }

    
}