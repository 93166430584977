@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.app{
  height: 100vh;
 
}

.topBar{
  background-color: $gray-900;
  height: 2.5rem;

  .chainName{
    font-size: smaller;
  }

  .accountBtn{
    padding: 0 0.3rem;
    text-decoration: none;
  }
}

.mintView{
  height: 100%;
}

.bottomBar{

  background-color: $gray-900;
  height: 2.5rem;

  font-size: x-small;

  padding-top:5px;
  padding-bottom:5px;

  a{
    &:hover{
      color: $gray-200;
    }

    color: $gray-500;
    text-decoration: none;
    
  }

}

.socialBar{

  background-color: $gray-900;
  height: 2.5rem;

  font-size: x-small;
  
  padding-top:5px;
  padding-bottom:5px;
  
  border-top: solid 1px gray;

  a{
    &:hover{
      color: $gray-200;
    }

    color: $gray-500;
    text-decoration: none;
    
  }

}